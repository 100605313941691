
import {
    defineComponent, computed, ref, PropType
} from 'vue';
import { byteLength } from 'byte-length';
import HttpRequest from '@/util/axios.config';
import dialogShell from '@/components/common/dialog-shell/index.vue';
import { SettingFormData } from '../device-info-setting-dialog/all.type';
import { getSetConfigOnceApiUrl } from '../device-info-setting-dialog/utils';

export default defineComponent({
    components: {
        dialogShell
    },
    props: {
        params: {
            type: Object as PropType<SettingFormData>,
            required: true
        },
        identity: {
            type: String
        },
        projectType: {
            type: String,
            default: ''
        }
    },
    setup(props, { emit }) {
        const close = () => {
            emit('close');
        };

        const onceContent = ref('');

        const isConfigToLong = computed(() => byteLength(onceContent.value) > 1024);

        const onceSubmit = () => {
            if (isConfigToLong.value) return;

            const url = getSetConfigOnceApiUrl(props.projectType);
            HttpRequest.post(url, {
                MAC: props.params.MAC,
                Config: onceContent.value
            }, [() => {
                emit('close');
            }, false]);
        };

        return {
            isConfigToLong,
            close,
            onceContent,
            onceSubmit
        };
    }
});
