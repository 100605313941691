interface ApiMap {
    community: string;
    office: string;
    single: string;
}

const getApiUrlByProjectType = <T extends ApiMap>(projectType: string, apiMap: T): string => {
    if (projectType === '0') return apiMap.community;
    if (projectType === '1') return apiMap.office;
    return apiMap.single;
};

export const getSetConfigApiUrl = (projectType: string) => {
    const apiMap = {
        community: 'v3/web/community/device/setConfig',
        office: 'v3/web/office/device/setConfig',
        single: 'v3/web/single/device/setConfig'
    };

    return getApiUrlByProjectType(projectType, apiMap);
};

export const getSetConfigOnceApiUrl = (projectType: string) => {
    const apiMap = {
        community: 'v3/web/community/device/setOnceConfig',
        office: 'v3/web/office/device/setOnceConfig',
        single: 'v3/web/single/device/setOnceConfig'
    };

    return getApiUrlByProjectType(projectType, apiMap);
};

export const getRebootApiUrl = (projectType: string) => {
    const apiMap = {
        community: 'v3/web/community/device/reboot',
        office: 'v3/web/office/device/reboot',
        single: 'v3/web/single/device/reboot'
    };

    return getApiUrlByProjectType(projectType, apiMap);
};

export const getRemoteApiUrl = (projectType: string) => {
    const apiMap = {
        community: 'v3/web/community/device/remote',
        office: 'v3/web/office/device/remote',
        single: 'v3/web/single/device/remote'
    };

    return getApiUrlByProjectType(projectType, apiMap);
};

export const getResetApiUrl = (projectType: string) => {
    const apiMap = {
        community: 'v3/web/community/device/reset',
        office: 'v3/web/office/device/reset',
        single: 'v3/web/single/device/reset'
    };

    return getApiUrlByProjectType(projectType, apiMap);
};
