import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_el_input = _resolveComponent("el-input")
  const _component_el_form_item = _resolveComponent("el-form-item")
  const _component_dialog_shell = _resolveComponent("dialog-shell")
  const _component_el_form = _resolveComponent("el-form")

  return (_openBlock(), _createBlock(_component_el_form, null, {
    default: _withCtx(() => [
      _createVNode(_component_dialog_shell, {
        title: _ctx.$wordList.ProperAllTextOnceAutop,
        onClose: _ctx.close,
        onSubmit: _ctx.onceSubmit
      }, {
        default: _withCtx(() => [
          _createVNode(_component_el_form_item, {
            error: _ctx.isConfigToLong ? _ctx.$wordList.OnceAutoPTooLong : null,
            "inline-message": ""
          }, {
            default: _withCtx(() => [
              _createVNode(_component_el_input, {
                modelValue: _ctx.onceContent,
                "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => (_ctx.onceContent = $event)),
                rows: "5",
                "auto-complete": "off",
                type: "textarea"
              }, null, 8, ["modelValue"])
            ]),
            _: 1
          }, 8, ["error"])
        ]),
        _: 1
      }, 8, ["title", "onClose", "onSubmit"])
    ]),
    _: 1
  }))
}